import { translate } from 'secullum-i18n';
import { formatDate } from 'secullum-react-native-ui';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { buscarTelaComImagemPendente } from '../../native/modules/imagemPendente';
import {
  DadosFuncionario,
  TipoCompensacao,
  TipoPerfil
} from '../../shared/modules/types';
import {
  exibirTelaInclusaoPonto,
  verificarExibirTela
} from './perfilFuncionario';
import { retornarTelas, DadosTela, Telas } from './telas';
import { converterDadosHorarioParaLista, estaProximoHorario } from './utils';

const CHAVE_CONFIGURACAO_TELA_INICIAL = 'ConfiguracaoTelaInicial';
const CHAVE_CONFIGURACAO_FORCAR_TELA_INICIAL = 'ConfiguracaoForcarTelaInicial';
const CHAVE_CONFIGURACAO_ULTIMA_TELA_ACESSADA =
  'ConfiguracaoUltimaTelaAcessada';

export const MINUTOS_LIMITE_FORCAR_TELA_INICIAL = 30;

export async function salvarConfiguracaoTelaInicialAsync(
  configuracaoTelaInicial: Telas
) {
  await AsyncStorage.setItem(
    CHAVE_CONFIGURACAO_TELA_INICIAL,
    JSON.stringify(configuracaoTelaInicial)
  );
}

export async function salvarUltimaTelaAcessadaAsync(
  telaAcessada: Telas,
  dadosFuncionario: DadosFuncionario
) {
  const listaTelasOpcaoInicial = retornarOpcoesTelasIniciais(dadosFuncionario);

  if (listaTelasOpcaoInicial.some(x => x.value == telaAcessada)) {
    await AsyncStorage.setItem(
      CHAVE_CONFIGURACAO_ULTIMA_TELA_ACESSADA,
      JSON.stringify(telaAcessada)
    );
  }
}

export async function carregarConfiguracaoTelaInicialAsync(
  dadosFuncionario: DadosFuncionario
): Promise<Telas> {
  const telaInicialJson = await AsyncStorage.getItem(
    CHAVE_CONFIGURACAO_TELA_INICIAL
  );
  const telaInicial = telaInicialJson && JSON.parse(telaInicialJson);

  // Caso o usuário ainda não tenha configuração definida, assume por padrão a última tela acessada
  if (telaInicial == null) {
    await salvarConfiguracaoTelaInicialAsync(Telas.UltimaTelaAcessada);
  }

  const ultimaTelaAcessadaJson = await AsyncStorage.getItem(
    CHAVE_CONFIGURACAO_ULTIMA_TELA_ACESSADA
  );
  const ultimaTelaAcessada: Telas =
    ultimaTelaAcessadaJson != null && JSON.parse(ultimaTelaAcessadaJson);

  let telaInicialSelecionada: Telas;

  if (telaInicial == Telas.UltimaTelaAcessada && ultimaTelaAcessada) {
    telaInicialSelecionada = ultimaTelaAcessada;
  } else if (telaInicial && telaInicial != Telas.UltimaTelaAcessada) {
    telaInicialSelecionada = telaInicial;
  } else {
    telaInicialSelecionada = Telas.Indicadores;
  }

  if (await deveForcarTelaInicial(dadosFuncionario)) {
    telaInicialSelecionada = Telas.IncluirPonto;
  }

  const opcoesTelasIniciais = retornarOpcoesTelasIniciais(dadosFuncionario);

  // Verifica se funcionário mudou para um perfil que não tem acesso à tela.
  // Ex: era gerente e virou funcionário.
  if (!opcoesTelasIniciais.some(x => x.value === telaInicialSelecionada)) {
    await salvarConfiguracaoTelaInicialAsync(Telas.UltimaTelaAcessada);
    telaInicialSelecionada = Telas.Indicadores;
  }

  const telaImagemPendente = await buscarTelaComImagemPendente();

  if (telaImagemPendente !== null) {
    telaInicialSelecionada = telaImagemPendente;
  }

  // Com a tela final selecionada, verifica se o usuário possui permissão de acesso, caso não tenha, direciona
  // para a primeira tela que o usuáro tiver permissao se ainda assim não listar nenhuma tela, então direciona
  // para a tela de configurações por padrão (esta tela deve ser exibida sempre e não entra na restrição de perfis).
  if (
    !verificarExibirTela(
      telaInicialSelecionada,
      dadosFuncionario.dadosPerfilFuncionario.telasOcultar
    )
  ) {
    var telasAcessoLiberado = Object.values(Telas).filter(
      x =>
        !(x == telaInicialSelecionada) &&
        !dadosFuncionario.dadosPerfilFuncionario.telasOcultar.some(
          c => c == x
        ) &&
        x != Telas.Login &&
        verificarPodeSerTelaInicial(x)
    );

    telaInicialSelecionada =
      telasAcessoLiberado.length > 0
        ? telasAcessoLiberado[0]
        : Telas.ErroSemTelasDisponiveis;
  }

  return telaInicialSelecionada;

  async function deveForcarTelaInicial(
    dadosFuncionario: DadosFuncionario
  ): Promise<boolean> {
    // Não sobrescreve a tela inicial quando o funcionário
    // não tem acesso a inclusão manual ou tem a tela de incluir ponto oculta.
    if (!exibirTelaInclusaoPonto(dadosFuncionario)) {
      return false;
    }

    // Não tem como saber o horário em que o funcionário bate ponto quando a compensação é diária.
    if (
      dadosFuncionario.dadosHorario.tipoCompensacao === TipoCompensacao.Diaria
    ) {
      return false;
    }

    const forcarTelaInicial =
      await carregarConfiguracaoForcarTelaInicialAsync();

    // Se a opção de forçar tela inicial foi desativada, não deve sobrescrever a tela inicial.
    if (!forcarTelaInicial) {
      return false;
    }

    const horaAtual = formatDate(new Date(), 'HH:mm');
    const listaHorarios = converterDadosHorarioParaLista(
      dadosFuncionario.listaHorarios
    );

    return listaHorarios.some(horario =>
      estaProximoHorario(horario, horaAtual, MINUTOS_LIMITE_FORCAR_TELA_INICIAL)
    );
  }
}

export function verificarPodeSerTelaInicial(tela: Telas) {
  const { telasDadosGerenciais, telasDadosPessoais, telasGerais } =
    retornarTelas();

  return [...telasDadosGerenciais, ...telasDadosPessoais, ...telasGerais].some(
    x => x.nome == tela
  );
}

export async function carregarConfiguracaoSelecionadaTelaInicialAsync(
  dadosFuncionario: DadosFuncionario
) {
  const telaInicialJson = await AsyncStorage.getItem(
    CHAVE_CONFIGURACAO_TELA_INICIAL
  );

  const telaInicial = telaInicialJson && JSON.parse(telaInicialJson);
  const opcoesTelasIniciais = retornarOpcoesTelasIniciais(dadosFuncionario);

  // Verifica se funcionário mudou para um perfil que não tem acesso à tela.
  // Ex: era gerente e virou funcionário.
  if (!opcoesTelasIniciais.some(x => x.value === telaInicial)) {
    return Telas.UltimaTelaAcessada;
  }

  return telaInicial;
}

export async function carregarConfiguracaoForcarTelaInicialAsync() {
  const forcarTelaInicialJson = await AsyncStorage.getItem(
    CHAVE_CONFIGURACAO_FORCAR_TELA_INICIAL
  );

  // Se não existir configuração, deve forçar a tela inicial.
  const forcarTelaInicial = forcarTelaInicialJson
    ? (JSON.parse(forcarTelaInicialJson) as boolean)
    : true;

  return forcarTelaInicial;
}

export const salvarForcarTelaInicialAsync = async (forcar: boolean) => {
  await AsyncStorage.setItem(
    CHAVE_CONFIGURACAO_FORCAR_TELA_INICIAL,
    JSON.stringify(forcar)
  );
};

export function retornarOpcoesTelasIniciais(funcionario: DadosFuncionario) {
  const { telasDadosGerenciais, telasDadosPessoais, telasGerais } =
    retornarTelas();

  const { telasOcultar } = funcionario.dadosPerfilFuncionario;

  const mapearTelaParaOpcao = (tela: DadosTela) => ({
    value: tela.nome,
    label: tela.titulo,
    nativeID: `tela-${tela.nome.toLowerCase()}`
  });

  const opcoesDadosGerenciais = telasDadosGerenciais
    .filter(
      x =>
        x.podeSerTelaInicial &&
        (!telasOcultar || !telasOcultar.some(c => c == x.nome))
    )
    .map(mapearTelaParaOpcao);

  const opcoesDadosPessoais = telasDadosPessoais
    .filter(
      x =>
        x.podeSerTelaInicial &&
        (x.nome !== Telas.IncluirPonto || funcionario.podeIncluirPontoManual) &&
        (x.nome !== Telas.AssinaturaDigitalCartaoPonto ||
          funcionario.assinaturaDigitalCartaoPontoHabilitada) &&
        (x.nome !== Telas.IncluirAtividade ||
          funcionario.podeIncluirAtividade) &&
        (!telasOcultar || !telasOcultar.some(c => c == x.nome))
    )
    .map(mapearTelaParaOpcao);

  const opcoesGerais = telasGerais
    .filter(
      x =>
        x.podeSerTelaInicial &&
        (!telasOcultar || !telasOcultar.some(c => c == x.nome))
    )
    .map(mapearTelaParaOpcao);

  return [
    ...(funcionario.dadosPerfilFuncionario.tipoPerfil === TipoPerfil.Gerente &&
    opcoesDadosGerenciais.length > 0
      ? opcoesDadosGerenciais
      : []),
    ...opcoesDadosPessoais,
    ...opcoesGerais,
    {
      label: translate('Última tela acessada'),
      value: Telas.UltimaTelaAcessada,
      nativeID: `tela-ultima-tela-acessada`
    }
  ];
}

export function buscarPrimeiraTelaInicialDisponivel(
  funcionario: DadosFuncionario,
  telaIgnorar: Telas
) {
  return buscarPrimeiraTelaInicialDisponivelIgnorarVariasTelas(funcionario, [
    telaIgnorar
  ]);
}

export function buscarPrimeiraTelaInicialDisponivelIgnorarVariasTelas(
  funcionario: DadosFuncionario,
  telasIgnorar: Array<Telas>
) {
  const telasIniciais = retornarOpcoesTelasIniciais(funcionario).filter(
    x => x.value !== Telas.UltimaTelaAcessada && !telasIgnorar.includes(x.value)
  );

  // Caso o usuário não tenha nenhuma outra tela disponível,
  // retorna a de configurações que é disponível para todo mundo.
  return telasIniciais.length > 0
    ? telasIniciais[0].value
    : Telas.Configuracoes;
}
