import { Platform } from 'react-native';
import { translate } from 'secullum-i18n';
import { getTheme } from '../../shared/modules/layout';
import {
  DadosFuncionario,
  TipoPerfil,
  TipoPlano
} from '../../shared/modules/types';
import { verificarExibirTela } from './perfilFuncionario';
import { retornarTelas, DadosTela, Telas, retornarRotaWeb } from './telas';

export function retornarMenu(
  funcionario: DadosFuncionario,
  isConnected: boolean
) {
  const theme = getTheme();

  const { telasDadosGerenciais, telasDadosPessoais, telasGerais } =
    retornarTelas();

  const { telasOcultar } = funcionario.dadosPerfilFuncionario;

  const mapearTelaParaMenu = (tela: DadosTela) => ({
    path: Platform.OS === 'web' ? retornarRotaWeb(tela.nome) : tela.nome,
    text: tela.titulo,
    nativeID: tela.nativeID,
    textStyle: {
      fontFamily: theme.fontFamily1,
      ...(Platform.OS !== 'web' &&
      !isConnected &&
      !verificarSuporteOffline(tela.nome, funcionario)
        ? { color: theme.borderColor1 }
        : {})
    }
  });

  const menuDadosGerenciais = telasDadosGerenciais
    .filter(x => !telasOcultar || !telasOcultar.some(c => c == x.nome))
    .map(mapearTelaParaMenu);

  const filtrarTelas = (tela: DadosTela) => {
    if (
      tela.nome === Telas.AssinaturaDigitalCartaoPonto &&
      !funcionario.assinaturaDigitalCartaoPontoHabilitada
    ) {
      return false;
    }

    if (
      tela.nome === Telas.IncluirPonto &&
      !funcionario.podeIncluirPontoManual
    ) {
      return false;
    }

    if (
      tela.nome === Telas.IncluirAtividade &&
      (!funcionario.podeIncluirAtividade || !funcionario.podeIncluirPontoManual)
    ) {
      return false;
    }

    if (
      tela.nome === Telas.Indicadores &&
      funcionario.dadosPerfilFuncionario.tipoPerfil ==
        TipoPerfil.FuncionarioRestrito
    ) {
      return false;
    }

    if (telasOcultar.some(c => c == tela.nome) && telasOcultar) {
      return false;
    }

    if (
      tela.nome === Telas.Arquivos &&
      funcionario.plano < TipoPlano.Ultimate
    ) {
      return false;
    }

    return true;
  };

  const menuDadosPessoais = telasDadosPessoais
    .filter(filtrarTelas)
    .map(mapearTelaParaMenu);

  const menuGerais = telasGerais
    .filter(x => !telasOcultar || !telasOcultar.some(c => c == x.nome))
    .map(mapearTelaParaMenu);

  const menuGerente = [];

  if (menuDadosGerenciais.length > 0) {
    menuGerente.push({
      text: translate('Dados Gerenciais'),
      submenu: menuDadosGerenciais,
      nativeID: 'menu-dados-gerenciais'
    });
  }

  if (menuDadosPessoais.length > 0) {
    menuGerente.push({
      text: translate('Dados Pessoais'),
      submenu: menuDadosPessoais,
      nativeID: 'menu-dados-pessoais'
    });
  }

  return [
    ...(menuDadosGerenciais.length > 0 &&
    funcionario.dadosPerfilFuncionario &&
    funcionario.dadosPerfilFuncionario.tipoPerfil == TipoPerfil.Gerente
      ? menuGerente
      : menuDadosPessoais),
    ...menuGerais
  ];
}

export function verificarSuporteOffline(
  tela: Telas,
  funcionario: DadosFuncionario
): boolean {
  const telasComSuporteOffline = [Telas.Configuracoes, Telas.Login];

  if (
    funcionario.plano >= TipoPlano.Pro &&
    funcionario.podeIncluirPontoManual &&
    funcionario.configuracoesIncluirPontoOffline.permitirInclusaoPontoOffline &&
    funcionario.dadosPerfilFuncionario &&
    verificarExibirTela(
      Telas.IncluirPonto,
      funcionario.dadosPerfilFuncionario.telasOcultar
    )
  ) {
    telasComSuporteOffline.push(Telas.IncluirPonto);
  }

  return telasComSuporteOffline.includes(tela);
}
